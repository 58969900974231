@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.remove-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.remove-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f1f1f;
}

.hero_profile_creation {
  background-image: url('https://imgur.com/nwPzijh.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
}

.section {
  background-image: url('https://www.transparenttextures.com/patterns/concrete-wall.png');
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
}
.section_no_shadow {
  background-image: url('https://www.transparenttextures.com/patterns/concrete-wall.png');
  backdrop-filter: blur(10px);
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
}
.section_bottom_shadow {
  background-image: url('https://www.transparenttextures.com/patterns/concrete-wall.png');
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(5px);
}

.section_list .li-nav {
  padding: 8px;
  color: #cfcdbf;
  transition: ease-in-out 300ms;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.section_list .li-nav:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.section_list .li-nav:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.section_list:hover .li-nav:hover {
  background: rgb(190 130 80);
  box-shadow: 0 4px 30px rgba(250, 156, 82, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  color: rgba(12, 12, 12, 0.8);
}


.section_list_row li {
  padding: 8px;
  color: #cfcdbf;
  transition: ease-in-out 300ms;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.section_list_row li:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.section_list_row li:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.section_list_row:hover li:hover {
  background: rgb(190 130 80);
  box-shadow: 0 4px 30px rgb(182, 126, 79);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  color: rgba(12, 12, 12, 0.8);
}

.section_list_crime li {
  padding: 8px;
  height: 8rem;
  color: rgba(207, 205, 191, 0.5);
  transition: ease-in-out 300ms;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.section_list_crime li:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.section_list_crime li:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.section_list_crime li:nth-child(1) {
  background: url(https://imgur.com/ytOeAGY.png) center;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(12, 12, 12, 0.8);
}
.section_list_crime li:nth-child(2) {
  background: url(https://imgur.com/D5MN1L6.png) top;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(12, 12, 12, 0.8);
}

.section_list_crime li:nth-child(3) {
  background: url(https://imgur.com/x87tN0d.png) center;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(12, 12, 12, 0.8);
}

.section_list_crime:hover li:hover {
  color: #cfcdbf;
  box-shadow: inset 0 0 0 2000px rgba(12, 12, 12, 0.24);
}

.section_list_gym li {
  padding: 8px;
  height: 8rem;
  color: rgba(207, 205, 191, 0.5);
  transition: ease-in-out 300ms;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.section_list_gym li:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.section_list_gym li:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.section_list_gym li:nth-child(1) {
  background: url(https://imgur.com/F68cyN7.png) center;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(12, 12, 12, 0.8);
}

.section_list_gym:hover li:hover {
  color: #cfcdbf;
  box-shadow: inset 0 0 0 2000px rgba(12, 12, 12, 0.24);
}

.crime_commit_button {
  border: solid 1px rgb(190 130 80);
  color: rgb(190 130 80);
  transition: ease-in-out 300ms;
}

.crime_commit_button:hover {
  background: rgb(190 130 80);
  box-shadow: 0 4px 30px rgb(182, 126, 79);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  color: rgba(12, 12, 12, 0.8);
}

.create_profile_button {
  background: rgb(190 130 80);
  color: rgba(12, 12, 12, 0.8);
  transition: ease-in-out 300ms;
}
.create_profile_button:disabled {
  opacity: 0.3;
}

.create_profile_button:enabled:hover {
  background: rgb(190 130 80);
  box-shadow: 0 4px 30px rgb(182, 126, 79);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  color: rgba(12, 12, 12, 0.8);
}

.news_alert {
  background: rgb(190 130 80);
  box-shadow: 0 4px 20px rgba(250, 156, 82, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
}

.section_orange {
  background: #BE8250FF url('https://www.transparenttextures.com/patterns/concrete-wall.png');
  box-shadow: 0 4px 20px rgba(250, 156, 82, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
}

.button_orange {
  background: #BE8250FF url('https://www.transparenttextures.com/patterns/concrete-wall.png');
  box-shadow: 0 4px 20px rgba(250, 156, 82, 0.5);
  backdrop-filter: blur(10px);
  color: rgba(12, 12, 12, 0.8);
  -webkit-backdrop-filter: blur(5px);
  transition: ease-in-out 300ms;
  cursor: pointer;
}

.button_orange:hover {
  box-shadow: 0 4px 30px rgb(182, 126, 79);
  backdrop-filter: blur(10px);
}

.button_default {
  background: #1F1F1F url('https://www.transparenttextures.com/patterns/concrete-wall.png');
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  color: #cfcdbf;
  -webkit-backdrop-filter: blur(5px);
  transition: ease-in-out 300ms;
  cursor: pointer;
}

.button_default:hover {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}

.button_light {
  background: #cfcdbf url('https://www.transparenttextures.com/patterns/concrete-wall.png');
  box-shadow: 0 1px 5px rgb(207, 205, 191);
  backdrop-filter: blur(10px);
  color: #1f1f1f;
  -webkit-backdrop-filter: blur(5px);
  transition: ease-in-out 300ms;
  cursor: pointer;
}

.button_light:hover {
  box-shadow: 0 1px 10px rgb(207, 205, 191);
  backdrop-filter: blur(10px);
}

.button_danger {
  background: #932d2e url('https://www.transparenttextures.com/patterns/concrete-wall.png');
  box-shadow: 0 1px 5px rgb(147, 45, 46);
  backdrop-filter: blur(10px);
  color: #cfcdbf;
  -webkit-backdrop-filter: blur(5px);
  transition: ease-in-out 300ms;
  cursor: pointer;
}

.button_danger:hover {
  box-shadow: 0 1px 10px rgb(147, 45, 46);
  backdrop-filter: blur(10px);
}

.marquee {
  --gap: 1rem;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  color: rgba(12, 12, 12, 0.8);
  font-weight: 300;


  /*margin-bottom: -2px;*/
  /*color: rgb(136, 94, 58);*/
  /*!*letter-spacing: 0.6px;*!*/
  /*font-weight: 300;*/
  /*font-size: 0.8rem;*/
  /*text-shadow: 0 1px 0 rgba(255, 255, 255, 0.17), 0 -1px 0 rgba(0,0,0,.7);*/
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.marquee__content {
  animation: scroll 40s linear infinite;
}

.property-image {
  background-image: url("https://imgur.com/eVJ3xwl.png");
  background-size: cover;
  background-position: center center;
  padding: 150px 200px;
  box-sizing: border-box;
  box-shadow: inset 0 0 70px 50px rgba(12, 12, 12, 0.8);
}

.text_effect {
  transition: ease-in-out 300ms;
  cursor: pointer;
}

.text_effect:hover {
  background: rgb(190 130 80);
  box-shadow: 0 4px 30px rgba(250, 156, 82, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  color: rgba(12, 12, 12, 0.8);
}


.select_effect {
  transition: ease-in-out 300ms;
  color: #cfcdbf;
  cursor: pointer;
}

.select_effect div {
  transition: ease-in-out 300ms;
  border: solid 1px rgba(182, 126, 79, 0.15);
}

.select_effect:hover div {
  border: solid 1px rgb(31, 31, 31);
}

.select_effect:hover {
  transition: ease-in-out 300ms;
  color: #1F1F1F;
  background: rgb(190 130 80);
  box-shadow: 0 4px 30px rgba(250, 156, 82, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #cfcdbf;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}